@tailwind base;
@tailwind components;
@tailwind utilities;

.theme-input {
  @apply text-black mt-1 block w-full rounded py-1 px-2 bg-gray-50 border border-gray-200 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50;
}

.theme-error {
  @apply text-red-500;
}

.theme-nav-title {
  @apply text-2xl font-bold print:text-lg;
}

.table-border {
  @apply border text-center text-lg p-2 print:text-xs print:border-black print:px-1 print:py-0;
}

.table-border-yellow {
  @apply table-border border-yellow-400 print:border-black;
}

.table-border-total {
  @apply table-border border-purple-400 print:border-black;
}

.table-border-male {
  @apply table-border border-blue-400 print:border-black;
}

.table-border-female {
  @apply table-border border-pink-400 print:border-black;
}
